import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { PageContextPropsType } from '../../../i18n/i18n'
import LayoutSimpleFooter from '../../components/LayoutSimpleFooter'
import SEO from '../../components/SEO'
import FormEbook from '../../components/FormEbook'
import classNames from 'classnames/bind'
import * as EbookPageStyles from '../../styles/EbookPage.css'

const cx = classNames.bind(EbookPageStyles)

export default function EbookPage({ pageContext }: PageContextPropsType) {
  const reCaptchaKey = process.env.GATSBY_RECAPTCHA_CLIENT_SECRET_KEY || ''
  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
      <LayoutSimpleFooter pageContext={pageContext}>
      <SEO
          title="【2023年総集編】インタビュー・ナレッジ記事まとめ | KARTE Blocks"
          description="KARTE Blocksのインタビュー・ナレッジ記事をまとめた資料をダウンロードいただけます。"
          path="/ebook/2023casestudy/"
        />

        <main className={cx('Outer')}>
          <div className={cx('Container')}>
            <section className={cx('Content')}>
              <h1 className={cx('Headline')}>
              【2023年総集編】
                <br/>
                インタビュー・ナレッジ記事まとめ
              </h1>

              <StaticImage src="../../images/ebook/2023casestudy-thumbnail.png" alt="資料の表紙" />

              <section className={cx('Details')}>
                <h2 className={cx('Details_Headline')}>資料の内容</h2>
                <p className={cx('Details_Summary')}>
                2023年はインタビュー・事例プラクティスに力を入れた年でした。13本のインタビューと、79本の事例が公開されています。他社での取り組み事例が欲しいとお声いただく機会が増え、一度でダウンロードできる資料を制作いたしました。全75ページのかなり読み応えのある資料になっております。
                </p>
                <div className={cx('Details_List_Wrapper')}>
                  <p className={cx('Details_List_Head')}>目次</p>
                  <ol className={cx('Details_Outline')}>
                    <li className={cx('Details_Item')}>
                      インタビュー記事
                      <ul className={cx('Details_List_Items')}>
                        <li className={cx('Details_List_Item')}>株式会社 TENTIAL</li>
                        <li className={cx('Details_List_Item')}>ディップ株式会社</li>
                        <li className={cx('Details_List_Item')}>株式会社タンガロイ</li>
                        <li className={cx('Details_List_Item')}>SHE株式会社</li>
                        <li className={cx('Details_List_Item')}>三井ダイレクト損害保険株式会社</li>
                        <li className={cx('Details_List_Item')}>株式会社デファクトスタンダード</li>
                        <li className={cx('Details_List_Item')}>株式会社overflow</li>
                        <li className={cx('Details_List_Item')}>エン・ジャパン株式会社 / engage</li>
                        <li className={cx('Details_List_Item')}>エン・ジャパン株式会社 / ミドルの転職</li>
                        <li className={cx('Details_List_Item')}>株式会社ネクストビート</li>
                        <li className={cx('Details_List_Item')}>トリコ株式会社</li>
                        <li className={cx('Details_List_Item')}>株式会社三陽商会</li>
                        <li className={cx('Details_List_Item')}>株式会社JIMOS</li>
                      </ul>
                    </li>
                    <li className={cx('Details_Item')}>ナレッジ記事</li>
                  </ol>
                </div>
              </section>
            </section>

            <FormEbook actionUrl="https://deal.karte.io/l/819613/2024-01-11/c8m1d" actionName="blockskarteio/ebook/2023casestudy/" />
          </div>
        </main>
      </LayoutSimpleFooter>
    </GoogleReCaptchaProvider>
  )
}
